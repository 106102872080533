import React from "react"
import PropTypes from "prop-types"
import Layout from "../../components/layout"
import Helmet from "react-helmet"

import Hero from "../../components/hero--product"
import Content from "../../components/content-with-image"
import H4 from "../../components/Utility/heading4"
import CenteredCTA from "../../components/cta--centered"
import { BVLoader, BVReviews } from "@tyson-foods/react-component-library"
import Collapsable from "../../components/Collapse/Collapse"
import CTA from "../../components/cta"

export default class ProductDetailPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>Plant Based Grilled Fillets</title>
          <meta
            name="description"
            content="Looking for some plant based grilled chicken? Try our Raised & Rooted™ Grilled Plant Based Fillets! It's the best meatless solution for your favorite recipe or meal."
          />
          <meta itemProp="name" content="Grilled Plant Based Fillets" />
        </Helmet>
        <Layout>
          <BVLoader
            clientName="raisedandrooted"
            siteID="main_site"
            env={process.env.GATSBY_BAZAARVOICE_ENV}
          />
          <div itemScope itemType="http://schema.org/Product">
            <Hero
              heroDirection="reverse"
              heroclassName="single"
              //headingTitleText1="Raised & Rooted™"
              headingTitleColor1="#006B2D"
              headingText1="Grilled Plant Based Fillets"
              headingColor1="#006B2D"
              bodyColor="#363636"
              bodyText="Who said plants couldn’t be meaty? Raised & Rooted&trade; Plant-Based Grilled Fillets are made with pea protein, lightly seasoned and expertly grilled. These juicy fillets will have you craving a mouth watering plant sandwich at your next BBQ. "
              itemColor="#006B2D"
              valueText1="11"
              unitText1="g"
              labelText1="Protein (per serving)"
              valueText2="0"
              unitText2="g"
              labelText2="Trans Fat*"
              dataImg3="/leaf.svg"
              labelText3="No Artificial Preservatives"
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
              productID="00053400000453"
            >
              <img src="/raised-and-rooted-grilled-plant-based-fillets-708x721.png" alt="Raised And Rooted Grilled Plant Based Fillets" />
            </Hero>
            <Content
              ctaclassName="reverse"
              ctaBGColor="#97D700"
              bodyColor="#006B2D"
              imgSrc="/raised-and-rooted-grilled-plant-based-fillets-lifestyle-pizza-708x721.png"
              altText="Raised And Rooted Grilled Plant Based Fillets Lifestyle Pizza"
              headingText2="WHAT’S INSIDE?"
              headingColor2="#006B2D"
              blurb="Raised & Rooted™ Grilled Fillets are made with plant based protein and the perfect amount of seasoning. They’re giving “tastes like chicken” a whole new meaning."
            >
              <h4>NUTRITION</h4>
              <p>
                11 grams of plant based protein per serving. Tons of real flavor with no artificial preservatives.
              </p>
              <h4>TASTE</h4>
              <p>
                Grilled chicken without the grill... or the chicken. 
                These tasty fillets are fully cooked and easy to love. 
                Packed with perfectly seasoned flavor and the delicious power of plants.
              </p>
            </Content>

            <div className="nutrition">
              <div>
                <Collapsable
                  className={"nutrition__prepared"}
                  text={"Preparation Instructions"}
                  collapsed={false}
                >
                  <b>HEATING INSTRUCTIONS</b>
                  <p>
                    Keep frozen until ready to use. 
                    Appliances vary. Heating times approximate.
                  </p>
                  <b>Conventional OVEN</b>
                  <p>
                    Preheat oven to 400°F. 
                    Arrange fillets on a baking sheet about 1-inch apart. 
                    Bake for 12-14 minutes or until hot.
                  </p>
                </Collapsable>
                <br />
                <Collapsable
                  className={"nutrition__details"}
                  text={"Nutrition"}
                  collapsed={true}
                >
                  <p>4 servings per container</p>
                  <p>Serving size 1 filet (84g)</p>
                  <p>Amount Per Serving</p>
                  <hr />
                  <table>
                    <tr>
                      <td colspan="2">Calories 160</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td colspan="2">% Daily Value *</td>
                    </tr>
                    <tr>
                      <td>Total Fat 9g</td>
                      <td>12%</td>
                    </tr>
                    <tr>
                      <td className="indent">Saturated Fat 1.5g</td>
                      <td>8%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Trans Fat 0g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Polyunsurated Fat 5g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Monounsurated Fat 2.5g
                      </td>
                    </tr>
                    <tr>
                      <td>Cholesterol 0mg</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Sodium 480mg</td>
                      <td>21%</td>
                    </tr>
                    <tr>
                      <td>Total Carbohydrate 8g</td>
                      <td>3%</td>
                    </tr>
                    <tr>
                      <td className="indent">Dietary Fiber 3g</td>
                      <td>11%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Total Sugars 1g
                      </td>
                    </tr>
                    <tr>
                      <td className="indent">Includes 0g Added Sugars</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Protein 11g</td>
                      <td>18%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>Vitamin D 0mcg</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Calcium 140mg</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>Iron 2.2mg</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>Potassium 400mg</td>
                      <td>8%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        *Percent daily values are based on a 2,000 calorie diet.
                        Your daily values may be higher or lower depending on
                        your calorie needs.
                      </td>
                    </tr>
                  </table>
                </Collapsable>
              </div>
              <Collapsable
                className={"nutrition__ingredients"}
                text={"Ingredients"}
                collapsed={true}
              >
                <p>
                    VEGAN FIBERS (WATER, PEA PROTEIN, CANOLAOIL, SODIUM ALGINATE, 
                    CONTAINS 2% OR LESS OF: CITRUS FIBER, CALCIUM CHLORIDE), WATER, 
                    PEA PROTEIN, SOYBEAN OIL, ROAST CHICKEN TYPE FLAVOR [MALTODEXTRIN 
                    (FROM CORN), NATURAL FLAVOR, YEAST EXTRACT, PALM OIL, CARROT FIBER, 
                    SALT, CITRIC ACID, MEDIUM CHAIN TRIGLYCERIDES, ONION POWDER, TORULA 
                    YEAST, ONION JUICE CONCENTRATE, SPICE], VITAL WHEAT GLUTEN, OAT FIBER, 
                    CONTAINS 2% OR LESS: CANOLA OIL, CARRAGEENAN, CITRIC ACID, CORN STARCH, 
                    METHYLCELLULOSE, POTATO STARCH, ROSEMARY EXTRACT, SEA SALT, SEASONING 
                    [MALTODEXTRIN, YEAST EXTRAGT, SALT, DEXTROSE, GARLIC POWDER, ONION POWDER, 
                    LEMON JUICE SOLIDS, NATURAL FLAVORS, SPICES, VINEGAR SOLIDS, MODIFIED CORN STARCH, 
                    CITRIC ACID, DEHYDRATED RED BELL PEPPER, GRILL FLAVOR (FROM SUNFLOWER OIL), 
                    DEHYDRATED PARSLEY], SUNFLOWER OIL, XANTHAN GUM.
                </p>
                <H4 headingText="Allergens" />
                <ul className="allergens">
                  <li>Contains: wheat</li>
                  <li>CONTAINS BIOENGINEERED FOOD INGREDIENTS</li>
                </ul>
              </Collapsable>
            </div>
            <div id="reviews" className="reviews">
              <BVReviews productID="00053400000453" />
            </div>
            <CenteredCTA
              ctaBottomMargin="30px"
              bgImage="/paper-background.jpg"
              badgeSrc="/hover.svg"
              headingText2="Where to Buy Us"
              headingColor2="#006B2D"
              bodyColor="#006B2D"
              bodyText="No need to search specialty stores. You’ll find our full lineup of plant based products in the refrigerated and frozen aisles of select supermarkets."
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
            />
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}
